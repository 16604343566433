<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            width="500"
            :min-width="200"
            :max-width="500"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 40em !important;">
                <img src="/static/images/delete_pop_icon.svg" style="height: 10rem !important" />
                <div class="mt-5">
                    <div v-if="org_selected.length == 1" class="mb-4" style="color:#f30d0d;font-size: 18px;">Delete Organization</div>
                    <div v-else class="mb-4" style="color:#f30d0d;font-size: 18px;">Delete Organizations</div>
                    <div class="m-8">
                        <div class="text-left fs-15 mb-3 fw-600" style="color:#00448b">
                            Note:
                        </div>
                        <div class="fs-15 fw-500 p-4 " style="border-radius: 5px;border: solid 0.5px #adadad;" >
                            Deleting your organisation will delete data of all 
                            associated companies, their employees, Product 
                            access and other valuable records. This action 
                            cannot be undone.
                        </div>
                    </div>
                    <div>
                        <span v-if="org_selected.length == 1" class="fs-15 fw-600">Are you sure want to delete your organisation?</span>
                        <span v-else class="fs-15 fw-600">Are you sure want to delete your organisation?</span>
                    </div>
                    <!-- <div class="text-center">
                        <span class="fs-13">
                            Deleting Organization will also delete All:
                        </span>
                        <div class="d-flex align-items-center flex-column mt-2">
                            <ul class="p-0">
                                <li class="fs-13 text-left">Companies</li>
                                <li class="fs-13 text-left">Business Units</li>
                                <li class="fs-13 text-left">Departments</li>
                                <li class="fs-13 text-left">Employees</li>
                                <li class="fs-13 text-left">Branches</li>
                                <li class="fs-13 text-left">Outlets</li>
                                <li class="fs-13 text-left">Products</li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <div class="d-flex align-items-center justify-content-center mt-6">        
                    <button
                       type="button"
                        class="btn btn-outline-secondary"
                        style="width: 120px;border-radius: 5px;"
                        @click="hideDeleteOrganizationModal"
                    >cancel</button>
                    <button
                        type="button"
                        class="btn btn-new-primary ml-4"
                        style="box-shadow: none;min-width: 5rem;"
                        @click="deleteOrganization"
                    >Delete Now</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

export default ({
    props:['modal_name', 'org_selected'],
    data(){
        return{

        }
    },
    methods:{
        hideDeleteOrganizationModal(){
            this.$modal.hide(this.modal_name);
            this.$emit('hideDeleteOrganizationModal');
        },
        deleteOrganization() {
            this.$modal.hide(this.modal_name);
            this.$emit('deleteOrganizationSuccess');
        }
    }
})

</script>

<style scoped>
.btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .btn-primary-outline{
        color: #2b6ad0;
		border-radius: 5px;
		padding-left: 35px;
		padding-right: 35px;
		text-transform: none;
		font-weight: 500;
		line-height: 30px;
		box-shadow: none;
		border-color: 2px solid #2b6ad0;
		box-shadow: none !important;
		font-weight: 500;
    }
</style>
